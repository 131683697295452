<template>
  <div class="search">
    <div class="search-box">
      <el-button type="primary" size="medium" @click="getList">查询</el-button>
      <el-button type="primary" size="medium" @click="addDialog = true;form={};isEdit=false">添加</el-button>
    </div>
  </div>
  <el-table :data="tableData">
    <el-table-column prop="id" label="ID"></el-table-column>
    <el-table-column prop="title" label="标题"></el-table-column>
    <el-table-column prop="image" label="LOGO">
      <template #default="scope">
        <ximg :src="scope.row.image"/>
      </template>
    </el-table-column>
    <el-table-column prop="click_count" label="点击量"></el-table-column>
    <el-table-column prop="weight" label="权重"></el-table-column>
    <el-table-column prop="disable" label="状态">
      <template #default="scope">
        <el-switch :active-value="1" v-model="scope.row.disable" disabled :inactive-value="0"></el-switch>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="150px">
      <template #default="scope">
        <el-button-group>
          <el-button @click="showEditDialog(scope.row,scope.$index)" size="small">编辑</el-button>
          <el-button @click="delApp(scope.row.id,scope.$index)" size="small">删除</el-button>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>
  <page :pager="pager" @query="getList()" />
  <el-dialog v-model="addDialog" :title="isEdit?'编辑':'添加'">
    <el-form label-width="80px">
      <el-form-item label="标题">
        <el-input v-model="form.title" placeholder="请输入轮播图标题"></el-input>
      </el-form-item>
      <el-form-item label="链接">
        <el-input v-model="form.link" placeholder="请输入轮播图链接"></el-input>
      </el-form-item>
      <el-form-item label="LOGO">
        <el-input v-model="form.image" placeholder="请输入轮播图地址"></el-input>
        <div>
          <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              accept="image/*"
              action="#"
              :http-request="upload"
              :before-upload="beforeAvatarUpload">
            <ximg v-if="form.image" :src="$store.getImage(form.image)" class="avatar"></ximg>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item label="状态">
        <el-switch :active-value="1" :inactive-value="0" v-model="form.disable" ></el-switch>
      </el-form-item>
<!--      <el-form-item label="点击量">-->
<!--        <el-input v-model="form.click_count" type="number" placeholder="请输入点击量"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="权重">
        <el-input v-model="form.weight" type="number" placeholder="请输入权重"></el-input>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="onAddSubmit">{{isEdit?"编辑":"添加"}}</el-button> <el-button @click="addDialog = false">关闭</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import httpClient from "@/config/httpClient";
import page from "@/components/page.vue";
import Ximg from "@/components/ximg.vue";
export default {
  name: "ad_community",
  components: {
    Ximg,
    page
  },
  data() {
    return {
      addDialog:false,
      isEdit:false,
      tableData:[],
      pager:{page:1,rows:20,total:0},
      search:{
      },
      form:{
        title:"",image:"",link:"",disable:0,weight:0
      },
      curRowIndex:0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    upload(param){
      this.$common.showLoading("上传中请稍后");
      httpClient("uploadFile").setHeader({"name":"download"}).upload(param.file).then((res) => {
        this.$common.hideLoading()
        if (res.code == 0) {
          this.$message({ message: res.msg, type: "success" });
          this.form.image = res.data.filename;
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      }).catch((err) => {
        this.$common.hideLoading()
        console.log(err);
      });
    },
    beforeAvatarUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/gif';
      console.log(isImage)
      // const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      // return isJPG && isLt2M;
      return true
    },
    showEditDialog(row,index){
      this.curRowIndex = index
      // this.editForm = {
      this.form = {
        id:row.id,
        title:row.title,
        image:row.image,
        link:row.link,
        disable:row.disable,
        weight:row.weight,
        // click_count:row.click_count,
      }
      this.isEdit = true
      this.addDialog = true
    },
    //获取广告
    getList() {
      let param = this.search
      param.page = this.pager.page -1
      param.row = this.pager.rows
      httpClient("getBannerList").post(param).then((res) => {
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }
      })
    },
    onAddSubmit(){
      if (this.isEdit){
        httpClient("updateBanner").post(this.form).then((res) => {
          if (res.code == 0){
            this.isEdit = false
            this.addDialog = false
            for(var key in this.form){
              this.tableData[this.curRowIndex][key] = this.form[key]
            }
          }
        })
      }else{
        httpClient("addBanner").post(this.form).then((res) => {
          if (res.code == 0){
            this.addDialog = false
            this.getList()
          }
        })
      }

    },
    delApp(id,index){
      this.$confirm("删除轮播", "删除轮播", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(() => {
        httpClient("delBanner").post({id:id}).then((res) => {
          if (res.code == 0){
            this.tableData.splice(index,1)
          }
        })
      })
    }
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>